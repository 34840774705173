/*
 * @Author: wxb
 * @Date: 2021-07-26 15:24:06
 * @LastEditTime: 2021-07-26 15:37:57
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\follow\index.js
 */
import request from '@/utils/request'
import qs from 'qs'

/**
 * @description: 关注/取消关注
 * @param {*} params
 * @return {*}
 */
export function follow(params) {
  return request({
    url: '/api/company/myAttention/follow',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 我的关注列表
 * @param {*} params
 * @return {*}
 */
export function queryFollow(params) {
  return request({
    url: '/api/company/myAttention/queryFollow',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
